/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-05-31",
    versionChannel: "nightly",
    buildDate: "2023-05-31T00:22:55.979Z",
    commitHash: "5d3544ddc75dc04f733661941ba11324b0d06df8",
};
